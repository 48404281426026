<template>
  <div class="i-left-panel shadow-one">
    <workspace-left-menu :refs="'w_sidebar'" />
    <button
      v-if="canCreate(project.cans)"
      @click="toggleCreateModal(true)"
      class="ws-folder-button focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one w-full mt-5 rounded flex items-center justify-center">
      Create Definition Set
    </button>
    <router-link
        :to="{ name: 'workspace_settings', params: { id: getWorkspaceId }, query: { type: 'project', id: project && project.id ? project.id : 0 } }"
        class="ws-folder-button focus:outline-none hover:bg-primary-three hover:text-primary-one text-primary-three fs-14 font-poppins fw-600 bg-primary-one shadow-one w-full mt-8 rounded flex items-center justify-center">
      Go Back
    </router-link>
    <div class="ws-folder-box overflow-y-auto definition-left-box">
      <div class="flex justify-between cursor-pointer text-primary-one opacity-60" @click="toggleDisplay('all')" :class="{'opacity-100': displayMode === 'all'}">
        <h2 class="fs-14 font-poppins fw-600">All Definition Sets</h2>
        <h2 class="fs-14 font-poppins fw-600">{{ definitionSets.length }}</h2>
      </div>
      <div>
        <div class="flex justify-between cursor-pointer text-primary-one opacity-60 mt-8"
             v-for="(category, cIndex) in categories"
             :key="cIndex"
             @click="toggleDisplay(cIndex)" :class="{'opacity-100': displayMode === cIndex}">
          <h2 class="fs-14 font-poppins fw-600">{{ category }}</h2>
          <h2 class="fs-14 font-poppins fw-600">{{ getCategoryItems(cIndex) }} / {{ definitionSets.length }}</h2>
        </div>
      </div>
      <div class="flex justify-between cursor-pointer text-primary-one opacity-60 mt-8" @click="toggleDisplay('deleted')" :class="{'opacity-100': displayMode === 'deleted'}">
        <h2 class="fs-14 font-poppins fw-600">Deleted</h2>
        <h2 class="fs-14 font-poppins fw-600">{{ definitionSets.filter(item => item.deleted_at).length }} / {{ definitionSets.length }}</h2>
      </div>
    </div>
  </div>
</template>

<script setup>
    import usePermission from '@/composable/usePermission.js'
    const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Sidebar",
  props: ['displayMode', 'categories'],
  created() {
    const self = this;
    self.$store.dispatch("project/getSolidObject", self.$route.params.id).then(response => {
      if(response && response.data) {
        this.$refs.w_sidebar.setWorkspace(self.project.workspace_id);
      }
    });
  },
  computed: {
    ...mapGetters({
      project: "project/project",
      definitionSets: 'definition/definitionSets'
    }),
    getWorkspaceId() {
      return this.project && this.project.workspace_id ? this.project.workspace_id : 0;
    }
  },
  methods: {
    toggleCreateModal(value) {
      this.$emit("toggleCreateModal", value);
    },
    toggleDisplay(mode) {
      this.$emit("toggleDisplay", mode);
    },
    getCategoryItems(index) {
      return this.definitionSets.filter(item => item.category_index === index).length;
    }
  }
}
</script>

<style scoped>
.definition-left-box {
  height: 600px;
}
</style>
