<template>
  <div class="relative flex font-poppins _main_wrapper">
    <sidebar :display-mode="displayMode"
             :categories="categories"
             @toggleCreateModal="toggleCreateModal" @toggleDisplay="toggleDisplay"/>

    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-8">
        <workspace-menu />
        <h2 class="fs-14 fw-700 font-poppins text-secondary-one absolute page-heading-title">Definition  Sets</h2>
        <div class="ws-action-box flex items-center justify-between ws-select-dropdown">
          <div class="flex scenario-action-box">
            <div class="flex">
              <div class="relative i-border-1 border-secondary-two rounded ws-search-width">
                <input
                    type="text"
                    class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two"
                    :placeholder="searchField.placeholder"
                    v-model="searchField.text"
                    @click="searchField.placeholder = ''"
                    v-click-outside="outsideSearchInput"
                />
                <div class="mr-2 flex items-center justify-between absolute inset-y-0 right-0 pointer-events-none pr-1">
                  <img
                      class="w-3 h-3"
                      src="/images/icons/search_icon.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="scenario-content-box pl-10 mr-8 mt-8 overflow-y-auto ws-scrollbar">
        <div class="flex flex-wrap gap-9 definition-box">
          <div class="scenario-item-box rounded shadow-one bg-primary-three i-pl-30 i-pr-30 i-pt-25 i-pb-25 mb-1"
               v-for="(item, index) in getDisplayData"
               :key="index">
            <div class="flex justify-between items-center i-border-b-1 border-secondary-two w-full pb-1">
              <router-link v-if="canRead(project.cans)" :to="{name: 'definition.show', params: {id: $route.params.id, definition_id: item.id}}">
                <h2 class="fs-14 font-poppins text-primary-one fw-600">{{ item.title }}</h2>
              </router-link>
              <h2 v-else class="fs-14 font-poppins text-primary-one fw-600">{{ item.title }}</h2>
              <div class="ws-project-opt-icon flex justify-center relative cursor-pointer" style="margin-top: -8px"
                   @mouseover="mouseOver($event, index)" v-click-outside="outsideOption"
                   @mouseleave="hoveredIndex = ''">
                <img src="/images/icons/workspace/project_opt.svg" alt="icon" class="mt-1 opacity-40" :class="{'opacity-100': parseInt(hoveredIndex) === parseInt(index)}">
                <div class="absolute ws-project-menus px-4 flex flex-col bg-primary-three shadow-two" v-if="parseInt(hoveredIndex) === parseInt(index)">
                  <router-link
                    v-if="canRead(project.cans)"
                    :to="{ name: 'definition.show', params: { id: $route.params.id, definition_id: item.id }}">
                    <h6 class="font-poppins fs-14 text-secondary-one opacity-60 pt-2 pb-1 cursor-pointer">Open</h6>
                  </router-link>
                  <a v-if="canEdit(project.cans)" class="font-poppins fs-14 text-secondary-one opacity-60 py-2 cursor-pointer" @click="toggleEditModal(true, item)">Edit</a>
                  <a v-if="canCreate(project.cans)" class="font-poppins fs-14 text-secondary-one opacity-60 py-2 cursor-pointer" @click="duplicateDefinition(item)">Duplicate</a>
                  <a v-if="canDelete(project.cans)" class="font-poppins fs-14 text-secondary-one opacity-60 py-2 cursor-pointer" @click="deleteDefinition(item.id, index)">Delete</a>
                </div>
              </div>
            </div>
            <h6 class="fs-12 font-poppins text-secondary-two mt-2">{{ categories[item.category_index] }}</h6>
            <div class="flex justify-between mt-4 items-center">
              <div>
                <h2 class="fs-14 font-poppins text-secondary-one">Definitions: <span>{{ item.items ? item.items.length : 0 }}</span></h2>
                <h2 class="fs-14 font-poppins text-secondary-one mt-2"><span class="text-secondary-one">Definition set</span></h2>
                <h2 class="fs-14 font-poppins text-secondary-one mt-2">Created: <span>{{ $lodash.format(item.created_at, 'dd/MM/yyyy') }}</span></h2>
                <h2 class="fs-14 font-poppins text-secondary-one mt-2">Last edited: <span>{{ $lodash.format(item.updated_at, 'dd/MM/yyyy') }}</span>
                </h2>
              </div>
              <div>
                <img :src="(item.file?.file_path) ? item.file?.file_path : '/images/definition/definition_demo_image.svg'" alt="demo" class="project-image shadow-two">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <create-definition :dModal="cModal"
                      :categories="categories"
                      @toggleCreateModal="toggleCreateModal"  />
    <edit-definition
                  :dModal="eModal"
                  :categories="categories"
                  :roadmap="editableData"
                  :definitionItem="editableData"
                  ref="definition_edit"
                  @toggleEditModal="(val, definition) => toggleEditModal(val, definition)" />
  </div>
</template>

<script setup>
    import usePermission from '@/composable/usePermission.js'
    const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
import Sidebar from "@/components/definition/management/Sidebar";
import CreateDefinition from "@/components/definition/management/DefinitionCreateModal";
import EditDefinition from "@/components/definition/management/DefinitionEditModal";
import {mapGetters} from "vuex";

export default {
  name: "Index",
  components: {EditDefinition, CreateDefinition, Sidebar},
  created() {
    document.title = "Definition";
    const self = this;
    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    self.$store.dispatch("definition/getDefinitionSets", { project_id: self.$route.params.id });
  },
  data() {
    return {
      displayMode: 'all',
      cModal: false,
      eModal: false,
      searchField: {
        placeholder: 'Search',
        text: ''
      },
      hoveredIndex: '',
      editableData: null,
      categories: ['Strategy & Transformation', 'Strategic Sales', 'Thought Leadership', 'Project Excellence'],
    }
  },
  computed: {
    ...mapGetters({
      definitionSets: 'definition/definitionSets',
      project: 'project/project',
    }),
    getDisplayData() {
      let definitionSets = [];
      if (this.displayMode === 'all') {
        definitionSets = this.definitionSets;
      } else if (this.displayMode === 'deleted') {
        definitionSets = this.definitionSets.filter(item => item.deleted_at);
      } else {
        definitionSets = this.definitionSets.filter(item => item.category_index === this.displayMode);
      }

      if (this.searchField.text) {
        return definitionSets.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      }
      return definitionSets;
    }
  },
  methods: {
    outsideSearchInput() {
      this.searchField.placeholder = 'Search';
    },
    toggleCreateModal(value) {
      this.cModal = value;
      this.editableData = null;
    },
    toggleDisplay(mode) {
      this.displayMode = mode;
      this.editableData = null;
    },
    toggleEditModal(value, item={})
    {
      this.eModal = value;
      this.editableData = item;
      this.$refs.definition_edit.setEditableData(item);
    },
    duplicateDefinition(item) {
      this.$Progress.start();
      this.$store.dispatch("definition/duplicateDefinitionSet", item).then(response => {
        this.$Progress.finish();
      });
    },
    deleteDefinition(id, index) {
      let payload = {
        definition_id: id,
        index: index,
        project_id: this.$route.params.id
      };
      this.$Progress.start();
      this.$store.dispatch("definition/deleteDefinitionSet", payload).then(response => {
        this.$Progress.finish();
      })
    },
    mouseOver(event, index) {
      this.hoveredIndex = index;
    },
    outsideOption() {
      this.hoveredIndex = '';
    },
  }
}
</script>

<style scoped>
  .project-image{
    display: block;
    height: 100px;
    width: 196px;
    object-fit: cover;
  }
/*   @media all and (max-width: 1850px){
    .definition-box{
      grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
      display: grid;
    }
    .definition-box>div{
      max-width: 600px;
      width: 100%;
    }
  } */
</style>
