<template>
  <Modal v-model="showModal" @close="showModal=false">
    <div class="p-10">
      <div class="flex justify-between pb-5 i-border-b-1 border-primary-one">
        <h3 class="font-poppins text-primary-one fs-26">Create Definition Set </h3>
      </div>

      <div class="i-mt-30">
        <div>
          <h4 class="font-poppins text-secondary-one fs-14">Definition set name </h4>
          <input type="text"
                 class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                 v-model="definition.title"
                 :placeholder="definitionPlaceholder.title"
                 @click="definitionPlaceholder.title = ''"
                 v-click-outside="outsideTitleInput">
        </div>

        <div class="i-mt-30">
          <h4 class="font-poppins text-secondary-one fs-14">Description</h4>
          <textarea class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 py-2 h-6r i-border-1 border-solid border-primary-one rounded bld-content-area"
                    v-model="definition.description"
                    :placeholder="definitionPlaceholder.description"
                    @click="definitionPlaceholder.description = ''"
                    v-click-outside="outsideDescription"></textarea>
        </div>

        <div class="i-mt-30">
          <h4 class="font-poppins text-secondary-one fs-14">Category</h4>
          <select class="bg-transparent text-secondary-two fs-14 w-full i-border-1 border-primary-one mt-2 py-2 rounded px-4" v-model="definition.category_index" placeholder="Select category">
            <option v-for="(category, cIndex) in categories" :key="cIndex" :value="cIndex">{{ category }}</option>
          </select>
        </div>

        <div class="i-mt-30">
          <h4 class="font-poppins text-secondary-one fs-14">Copy From Existing</h4>
          <select class="bg-transparent text-secondary-two fs-14 w-full i-border-1 border-primary-one mt-2 py-2 rounded px-4" v-model="definition.definition_id" placeholder="Select category">
            <option value="null" selected="true">Select Definition from Library</option>
            <option v-for="(wDefinition, dIndex) in workspaceDefinitions" :key="dIndex" :value="wDefinition.id">{{ wDefinition.title }}</option>
          </select>
        </div>

        <div class="i-mt-30 flex gap-6 items-center">
            <label class=" flex gap-3 items-center cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/></svg>
                <span>File Upload</span>
                <input @input="(e)=>{onChange(e), fileUpload(file)}" hidden type="file" accept="image/x-png,image/jpeg" />
            </label>
            <img class="w-10 h-8 object-cover" :src="previewURL" />
        </div>

        <div class="flex i-mt-30">
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one" @click="createDefinition()" :disabled="disableClick">Create</button>
          <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40" @click="closeModal()" :disabled="disableClick">Cancel</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
    import { useFile } from '@/composable/useFile.js'
    const { onChange, previewURL, file } = useFile()
</script>

<script>
import {mapGetters} from "vuex";
import Modal from '@/elements/atom/Modal.vue'

export default {
  components: { Modal },
  name: "CreateDefinition",
  props: ['dModal', 'categories'],
  data() {
    return {
      showModal: this.dModal,
      disableClick: false,
      definition: {
        title: '',
        description: '',
        category_index: 0,
        cover_image: null,
        file: null,
        definition_id: null
      },
      definitionPlaceholder: {
        title: 'Type title here',
        description: 'Your description here'
      },
      formData: new FormData()
    }
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING_STATE",
      activeWorkspaceObj: "workspace/activeWorkspaceObj",
      workspaceDefinitions: "definition/workspaceDefinitions",
    })
  },
  watch: {
    dModal: function (val) {
      if(val) {
        if(this.activeWorkspaceObj && this.activeWorkspaceObj.id) {
          this.$store.dispatch("definition/getWorkspaceDefinition", this.activeWorkspaceObj.id);
        }
        // this.$modal.show('definition-create-modal');
        this.showModal = val
      }
    }
  },
  methods: {
    closeModal() {
      // this.$modal.hide('definition-create-modal');
      this.$emit("toggleCreateModal", false);
    },
    outsideTitleInput() {
      this.definitionPlaceholder.title = 'Type title here';
    },
    outsideDescription() {
      this.definitionPlaceholder.description = 'Type description here';
    },
    appends(data) {
        for(let key in data){
            this.formData.append(key, data[key])
        }
    },
    fileUpload(file){
        this.definition.image = file
    },
    createDefinition() {
      const self = this;
      if(!this.definition.definition_id || this.definition.definition_id == null || this.definition.definition_id == 'null') {
        this.definition.definition_id = null;
      }

      self.$Progress.start();
      this.definition.project_id = self.$route.params.id;

      this.appends(this.definition)
      self.disableClick = true;
      self.$store.dispatch("definition/storeDefinitionSet", this.formData).then(response => {
        self.disableClick = false;
        this.$Progress.finish();
        if(response && response.data && response.data.status && response.data.data && response.data.data.id) {
          self.closeModal();
          self.definition = {
            title: '',
            description: '',
            category_index: 0
          };
          this.$store.dispatch("getDefinitionSet", { project_id: self.$route.params.id });
          self.$router.push({ name: 'definition.show', params: {id: self.$route.params.id, definition_id: response.data.data.id} });
        }
      });
    },
    uploadFile() {
      this.$refs.file.click();
    },
    addFile(e) {
      let droppedFiles = e.target.files || e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.definition.file = f;
        let fileUrl = URL.createObjectURL(f);
        this.definition.cover_image = fileUrl;
      });
    },
  }
}
</script>

<style scoped>
.h-6r {
  height: 6rem;
}
.cover-image-box {
  width: 100%;
  height: 120px;
  background-size: cover;
}
.image-upload-icon {
  width: 26px;
  height: 26px;
}
</style>
